.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


:root {
  --sp-0: 0px;
  --sp-1: 4px;
  --sp-2: 8px;
  --sp-3: 12px;
  --sp-4: 16px;
  --sp-5: 20px;
  --sp-6: 24px;
  --sp-7: 32px;
  --sp-8: 40px;
  --sp-9: 48px;
  --sp-10: 64px;
  --radius-1: 4px;
  --radius-2: 8px;
  --border-1px: 1px solid rgba(var(--theme-text_light), 0.15);
  --size-1: 20px;
  --size-2: 24px;
  --size-3: 30px;
  --size-4: 36px;
  --size-5: 44px;
  --size-6: 56px;
  --size-7: 72px;
  --size-8: 92px;
  --positive-dark: #2C8C69;
  --positive-light: #e3f3ec;
  --negative-dark: #C53030;
  --negative-light: #FFF5F5;
  --intermediate-dark: #DD6B20;
  --intermediate-light: #FFFAF0;
  --informational-dark: #2981bf;
  --informational-light: #e9f0f5;
  --general-dark: #7f858d;
  --general-light: #f0f4f8;
  --box--boxShadow: 0px 2px 4px -1px rgba(var(--theme-area_background_dark), 0.1);
  --box--backgroundColor: rgb(var(--theme-area_background_reset));
  --box--radius: var(--radius-1);
  --solved--borderColor: 44, 140, 105;
  --popup--radius: var(--radius-1);
  --popup--border: 1px solid rgba(var(--theme-area_background_dark), 0.1);
  --popup--boxShadow: 0 8px 50px rgba(0, 0, 0, 0.1), 0px 4px 15px rgba(0, 0, 0, 0.05);
  --dialog--boxShadow: 0px 5px 55px rgba(var(--theme-area_background_dark), 0.4), 0px 7px 24px -12px rgba(var(--theme-area_background_dark), 0.7);
  --header--height: 80px;
  --responsive-header--height: 55px;
  --logo--height: 100%;
  --responsive-logo--height: var(--logo--height);
  --primary-navigation--height: 52px;
  --secondary-navigation--height: 50px;
  --form-field--radius: var(--radius-1);
  --form-field--borderColor: rgba(var(--theme-text_light), 0.4);
  --form-field--borderHoverColor: rgba(var(--theme-text_light), 0.8);
  --form-field--focusShadow: rgba(var(--theme-active_input_border), 0.3) 0px 0px 0px 4px;
  --button--radius: var(--radius-1);
  --trophy-first--background: #fac746;
  --trophy-first--color: #704926;
  --trophy-second--background: #c0c0c0;
  --trophy-third--background: #cd7f32;
  --container--width: 1340px;
  --minimal_container--width: 1000px;
  --theme-active_input_border: 52, 128, 187;
  --theme-alternate_button: 23, 126, 201;
  --theme-alternate_button_font: 255, 255, 255;
  --theme-area_background: 237, 242, 247;
  --theme-area_background_dark: 45, 55, 72;
  --theme-area_background_light: 247, 250, 252;
  --theme-area_background_reset: 255, 255, 255;
  --theme-brand_primary: 23, 126, 201;
  --theme-button_bar: 255, 255, 255;
  --theme-comment_count: 237, 242, 247;
  --theme-comment_count_font: 45, 55, 72;
  --theme-featured: 44, 140, 105;
  --theme-footer_text: 53, 60, 65;
  --theme-header: 255, 255, 255;
  --theme-header_text: 45, 55, 72;
  --theme-important_button: 23, 126, 201;
  --theme-important_button_font: 255, 255, 255;
  --theme-item_status: 18, 101, 161;
  --theme-light_button: 232, 242, 250;
  --theme-light_button_font: 14, 76, 121;
  --theme-link: 26, 32, 44;
  --theme-link_button: 23, 126, 201;
  --theme-link_hover: 23, 126, 201;
  --theme-main_nav: 18, 101, 161;
  --theme-main_nav_bold: #c0083e;
  --theme-main_nav_font: 255, 255, 255;
  --theme-main_nav_tab: 255, 255, 255;
  --theme-main_nav_tab_font: 53, 60, 65;
  --theme-mentions: 61, 101, 148;
  --theme-mobile_back: 232, 233, 235;
  --theme-mobile_back_font: 53, 60, 65;
  --theme-moderated: 247, 237, 241;
  --theme-moderated_light: 252, 247, 249;
  --theme-moderated_text: 136, 14, 79;
  --theme-moderated_text_light: 164, 118, 143;
  --theme-normal_button: 23, 126, 201;
  --theme-normal_button_font: 255, 255, 255;
  --theme-notification_bubble: 54, 171, 128;
  --theme-page_background: 237, 240, 244;
  --theme-pagination_active: 62, 65, 72;
  --theme-poll_bar: 61, 101, 148;
  --theme-post_highlight: 253, 245, 231;
  --theme-post_highlight_border: 129, 98, 46;
  --theme-prefix: 23, 126, 201;
  --theme-price: 182, 63, 63;
  --theme-primary_button: 23, 126, 201;
  --theme-primary_button_font: 255, 255, 255;
  --theme-profile_header: 53, 60, 65;
  --theme-rating_hover: 61, 101, 148;
  --theme-rating_on: 255, 152, 0;
  --theme-secondary_title: 235, 237, 240;
  --theme-secondary_title_font: 130, 142, 153;
  --theme-section_title: 255, 255, 255;
  --theme-section_title_font: 26, 32, 44;
  --theme-selected: 235, 244, 249;
  --theme-selected_border: 23, 126, 201;
  --theme-step_background: 61, 101, 148;
  --theme-subscription_active_color: 61, 101, 148;
  --theme-tab_background: 74, 85, 104;
  --theme-tag: 255, 244, 223;
  --theme-tag_font: 156, 66, 33;
  --theme-text_color: 53, 60, 65;
  --theme-text_dark: 0, 0, 0;
  --theme-text_light: 113, 128, 150;
  --theme-text_very_light: 255, 255, 255;
  --theme-timeline_color: 45, 55, 72;
  --theme-trophy_first: 250, 199, 70;
  --theme-trophy_first_font: 112, 73, 38;
  --theme-trophy_second: 192, 192, 192;
  --theme-trophy_third: 205, 127, 50;
  --theme-very_light_button: 255, 255, 255;
  --theme-very_light_button_font: 14, 76, 121;
  --theme-widget_title_bar: 255, 255, 255;
  --theme-widget_title_font: 26, 32, 44;
}

#ipsLayout_header {
  padding: 0 !important;
}



/* nav bar haedaer */
#ipsLayout_header nav {
  /* background: rgb(var(--theme-main_nav)); */
  /* background: #c0083e; */
  position: relative;
  z-index: 999;
  width: 100%;
  margin: 0;
  padding: 0;
  /* background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: #fff;
  box-shadow: 0px 4px 2px #81afe9;


}

.ipsNavBar_primary>ul,
.ipsNavBar_secondary {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ipsNavBar_primary>ul {
  min-width: 0;
  flex: 1 1 auto;
}

.ipsNavBar_primary>ul>li,
.ipsNavBar_secondary>li {
  display: inline-block;
}

html[dir="ltr"] .ipsNavBar_primary>ul>li,
html[dir="ltr"] .ipsNavBar_secondary>li {
  float: left;
}

html[dir="rtl"] .ipsNavBar_primary>ul>li,
html[dir="rtl"] .ipsNavBar_secondary>li {
  float: right;
}

.ipsNavBar_secondary>li {
  position: relative;
}

.ipsNavBar_secondary>li .ipsNotificationCount {
  top: 3px;
}

.ipsNavBar_primary>ul>li>a,
.ipsNavBar_secondary>li>a {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.ipsNavBar_primary {
  position: relative;
  /* margin-bottom: var(--secondary-navigation--height); */
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px rgb(92, 90, 90);
}

.ipsLayout_minimalNoHome .ipsNavBar_primary {
  display: none;
}

.ipsNavBar_primary.ipsNavBar_noSubBars {
  margin-bottom: 0;
}

.ipsNavBar_primary>ul>li>a {
  font-size: 12px;
  /* color: rgba(var(--theme-main_nav_font), 0.7); */
  color: #000;
  padding: 0px 8px;
  /* line-height: var(--primary-navigation--height); */
  line-height: 20px;
  font-weight: 500;
}



.ipsNavBar_primary>ul>li>i {
  text-align: center !important;

}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 800px) {
  .ipsNavBar_primary>ul>li>a {
    font-size: 10px;
    /* color: rgba(var(--theme-main_nav_font), 0.7); */
    padding: 0px 6px;
    /* line-height: var(--primary-navigation--height); */
    line-height: 20px;
    color: #000;
    font-weight: 500;


  }
}

@media (min-width: 800px) and (max-width: 1075px) {
  .ipsNavBar_primary>ul>li>a {
    font-size: 12px;
    /* color: rgba(var(--theme-main_nav_font), 0.7); */
    padding: 0px 12px;
    line-height: var(--primary-navigation--height);
    color: #000;
    font-weight: 500;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
/* @media(max-width: 600px) {
  #ipsLayout_header nav {
    display: none;
  }
} */
/* @media (max-width: 400px){
  .small-block{
      display: block !important ;
      font-size: 20px !important;
  }
} */

html[dir="ltr"] .ipsNavBar_primary>ul>li>a .ipsNotificationCount {
  top: -2px;
  right: 2px;
}

html[dir="rtl"] .ipsNavBar_primary>ul>li>a .ipsNotificationCount {
  top: -2px;
  left: 2px;
}

.ipsNavBar_primary>ul>li>a:hover,
.ipsNavBar_primary>ul>li.ipsNavBar_active>a {
  /* color: rgb(var(--theme-main_nav_font)); */
  color: blue;
}

.ipsNavBar_active__identifier {
  display: none;
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  border-bottom-color: rgb(var(--theme-main_nav_tab));
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ipsNavBar_noSubBars .ipsNavBar_active__identifier {
  border-bottom-color: rgb(var(--theme-page_background));
}

.ipsNavBar_active .ipsNavBar_active__identifier {
  display: block;
}

.ipsNavBar_secondary .ipsNavBar_active__identifier {
  display: none !important;
}

.ipsNavBar_primary>ul>li>a[data-navdefault] {
  font-weight: 600;
}

.ipsNavBar_primary>ul>li.ipsNavBar_active>.ipsNavBar_secondary,
.ipsJS_none .ipsNavBar_primary>ul>li>a:hover+.ipsNavBar_secondary {
  display: block;
}

#ipsLayout_header nav::after {
  /* height: var(--secondary-navigation--height); */
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgb(var(--theme-main_nav_tab));
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
}

#ipsLayout_header nav.ipsNavBar_noSubBars::after,
.ipsLayout_minimal #ipsLayout_header nav::after {
  display: none;
}

.ipsNavBar_secondary {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
  /* background-color: #ffebcddb !important; */
  /* background-color: #ffddd2 !important; */
  height: 40px !important;
  background: linear-gradient(to right, #c5d9eb, #6db5dd, #b2dcf0, #728bbe);
}

@media (min-width: 481px) and (max-width: 1022px) {
  .ipsNavBar_secondary {
    height: 50px !important;

  }
}

/* @media (max-width: 1100px) {
  .ipsNavBar_secondary {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    display: none;
    background-color: #ffebcddb !important;
    height: 58px !important;
  }
} */
.ipsNavBar_secondary>li>a {
  /* color: rgba(var(--theme-main_nav_tab_font), 0.6); */
  color: #153a5b;
  font-size: 13.0px;
  padding: 0px 18px;
  line-height: 0px !important;
  /* line-height: var(--secondary-navigation--height); */
  float: left;
  font-weight: 700;
}

@media (min-width: 481px) and (max-width: 768px) {
  .ipsNavBar_secondary>li>a {
    /* color: rgba(var(--theme-main_nav_tab_font), 0.6); */
    color: #153a5b;
    font-size: 12px;
    padding: 0px 12px;
    line-height: var(--secondary-navigation--height);
    float: left;
    font-weight: 700;
  }

}

.ipsNavBar_secondary>li>a:hover {
  color: rgb(var(--theme-main_nav_tab_font));
  color: #c0083e;
}

.ipsNavBar_secondary>li.ipsNavBar_active a {
  font-weight: bold;
  color: rgb(var(--theme-main_nav_tab_font));
}

.ipsNavBar_primary .ipsMenu:not(#elSearchFilter_menu),
.ipsNavBar_secondary .ipsMenu {
  margin-top: -3px;
}

.dropdown-custom {
  background: rgb(var(--theme-area_background_reset)) !important;
  box-shadow: var(--popup--boxShadow) !important;
  border: var(--popup--border) !important;
  background-clip: padding-box !important;
  border-radius: var(--popup--radius) !important;
  z-index: 10000 !important;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  /* background-color: rgba(var(--theme-text_color), 0.05) !important; */
  background-color: #e9f5ff !important;
  /* color: rgb(var(--theme-text_color)) !important; */
  color: #95113b !important;
}

.dropdown-menu .dropdown-item {
  display: block !important;
  padding: 11px 20px !important;
  color: rgb(var(--theme-text_color)) !important;
  width: auto;
  margin: 0 !important;
}

.nav-tabs {
  border: none !important;
  /* border-color: none !important;
  background-color: none !important;
  border-bottom: none !important; */
}

.nav-tabs .nav-link {
  transition: .3s border-color !important;
  font-weight: 600 !important;
  border: 0 !important;
  color: inherit !;
  border-bottom: 3px solid transparent !important;
  margin-bottom: 0px !important;
  padding: 5px 2px !important;
  font-size: 11px;
}

/* .nav-tabs .nav-link.active {
  border-color: #E74C3C !important;
  color: #E74C3C !important;
  color: rgb(var(--theme-main_nav)) !important;
  border-color: rgb(var(--theme-main_nav)) !important
  color: #c0083e !important;
  color: #fff !important;
  border-color: #fff !important
} */

/* for text-filed */

.text-field {
  position: relative;
  margin-top: 12px;
}

/* .text-field span {
  color: #b91818;
  font-size: 13px;
} */
.text-field p {
  color: #4690e1;
  font-size: 13px;
  position: absolute;
  top: -18px;
  right: 5px;
  /* left: 0px; */
}

.text-field input,
.text-field textarea {
  display: inline-block;
  /* border: 1px solid darkgrey; */
  border: 1px solid #ced4da;
  /*  border-bottom: 1px solid var(--green);  */
  color: var(--labelColor);
  background-color: #fff;
  padding: 5px 8px 3px 8px;
  width: 100%;
  border-radius: 3px;
  font-size: 14px;
  /* margin-top: 4px; */
}

/* .text-field input:focus,
.text-field textarea:focus {
  border: 1px solid #dddddd !important;
  border: 1px solid #4690e1 !important;
  background-color: #fff;
  outline: none;
} */

.text-field label {
  /* color: #212529; */
  /* font-size: 16px; */
  position: absolute;
  pointer-events: none;
  left: 3px;
  top: -21px;
  transition: 0.2s;
  /* transition-property: opacity, transform; */
  font-weight: 700;
  /* font-style: normal; */
  /* font-stretch: normal; */
  /* font-optical-sizing: auto; */
  /* text-align: start; */
  /* opacity: 1; */
  /* font-family: "Raleway variant0", Tofu; */
  font-family: 'Poppins', sans-serif;
  /* letter-spacing: 2px; */
  /* font-family: "Montserrat" !important; */
  /* color: #6b6b6b;
   */
  /* color:#000000a8 ; */
  /* color:#474040 ; */
  color: #2e323c;
  font-size: .75rem;
  text-transform: uppercase;
  /* letter-spacing: 1px; */
  /* font-weight: 600; */
}

.r-only {
  top: -7px !important;
  left: 5px !important;
  font-size: 11px !important;
  color: red !important;
  background-color: #fff !important;
  padding: 0px 0px;
  border-radius: 4px;
}

input[placeholder],
[placeholder],
*[placeholder] {
  font-size: 15px;
  font-weight: 500;
}

input[value],
[value],
*[value] {
  font-size: 15px;
  font-weight: 400;
  color: #495057;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 15px;
  color: #1a1a1a;
  font-weight: 500;
}

.text-field input:focus,
.text-field textarea:focus {
  /* border: 1px solid #dddddd !important; */
  /* border: 1px solid #4690e1 !important; */
  background-color: #fff;
  outline: none;
  box-shadow: none !important;
  border-color: #2e69ff;
  box-shadow: 0 0 0 3px rgba(46, 105, 255, .25);
}

/* Color Box */

.requiredColor {
  /* background-color: #f5cfcf !important; */
  background-color: #fce9bf !important;
}

.readonlyColor {
  background-color: #c8c1c1 !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.bg-line {
  /*background-color: rgba(24, 143, 255, 0.16);*/
  /*background-color: #3e8ee5;*/
  /*background-color: #5390d9;*/
  /*#1a508b,#247ba0e0,247ba0*/
  /*background: linear-gradient(to right, #2c7493, #5390d9);*/
  /*background: linear-gradient(to right, #3a7bd5, #00d2ff);*/
  /* background: linear-gradient(to right, #1265a1, #247ba0); */
  color: #fff !important;
  /* background: linear-gradient(90deg, #d93065 -6.03%, #95113b 109.91%) !important; */
  box-shadow: 0 4px 13px rgb(45 0 14 / 30%);
  /* background-color: #153a5b; */
  /* background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-color: #223035;
}

.form-group {
  margin-bottom: 8px !important;
}

.date__box {
  margin-top: 14px;
  position: relative;
  color: #333;

}

.date__box label {
  position: absolute;
  top: -14px;
  left: 6px;
  font-size: 12px;
  padding: 0px 4px;
  color: #333;
  font-weight: bold;
}

.btn-success {
  /* background-color: #1265a1 !important;
  border-color: #1265a1 !important; */
  background-color: #223035 !important;
  border-color: #223035 !important;
  /* background: linear-gradient(90deg, #d93065 -6.03%, #95113b 109.91%) !important; */
  /* box-shadow: 0 4px 13px rgb(45 0 14 / 31%) !important; */
  /* border-color: #d93065 !important; */
  /* background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6); */

}

.bt {
  border-top: 1px solid #dee2e6;
}

.nav-tabs .nav-link.active {
  background: none !important;
}

.tab-header {
  padding: 0px;
  margin: 0px;
}

.tab-header.nav-tabs {
  padding: 0px !important;
  margin: 0px !important;
}

.tab-header .nav-link:hover {
  color: #fff !important;
}

.tab-header .nav-link {
  /* padding: 0px 18px !important; */
  font-size: 13px !important;
}

/* for close icon in challan tab */
.close {
  color: #fff !important;
  opacity: 1 !important;
}

.close:hover {
  color: #fff !important;
  opacity: 0.8 !important;
}


/* for more sub-menu dropdown */
.overlay-bg {
  background-color: rgba(0, 0, 0, .3);
  width: 100%;
  /* height: 100vh; */
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 100px;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  z-index: -100;
  overflow-y: auto;
}

@media (max-width: 1280px) {
  .overlay-bg {
    background-color: rgba(0, 0, 0, .3);
    width: 100%;
    /* height: 100vh; */
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 147px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    z-index: -100;
    overflow-y: auto;
  }

}

@media (max-width: 1050px) {
  .overlay-bg {
    background-color: rgba(0, 0, 0, .3);
    width: 100%;
    /* height: 100vh; */
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 214px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    z-index: -100;
    overflow-y: auto;
  }

}

@media (max-width: 768px) {
  .overlay-bg {
    background-color: rgba(0, 0, 0, .3);
    width: 100%;
    /* height: 100vh; */
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 152px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    z-index: -100;
    overflow-y: auto;
  }

}

@media (min-width: 400px) and (max-width:750px) {
  .overlay-bg {
    background-color: rgba(0, 0, 0, .3);
    width: 100%;
    /* height: 100vh; */
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 204px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    z-index: -100;
    overflow-y: auto;
  }

}

.overlay-1 {
  position: absolute;
  background-color: #FFFFFF;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 1);
  top: 0px;
  left: 0;
  right: 0;
  width: 50%;
  margin: auto;
  transition: all .5s;

}

@media (min-width: 481px) and (max-width: 1100px) {
  .overlay-1 {
    position: absolute;
    background-color: #FFFFFF;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, .1);
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    transition: all .5s;

  }
}


.Top-wear {
  color: #ee5f73;
  font-weight: bold;
  font-size: 16px;
}

.items:hover .overlay-1 {
  opacity: 1;
  visibility: visible;
  top: 84px;
  transition: all .3s;
}

.nav-overlay:hover .overlay-bg {
  /* top: 82.5px; */
  visibility: visible;
  opacity: 1;
  transition: all .3s;
}

.links {
  color: #282c3f;
  line-height: 23px !important;
  font-weight: 400;
  font-size: 15px;
}

.links:hover {
  color: black;
  font-weight: 700;
}

.drop-more li a {
  text-decoration: none;
}

.drop-more li {
  list-style: none;
  padding: 3px 0;
}

.small-block {
  font-size: 22px;
  text-align: center;
  font-size: 20px;
  margin-top: 55%;
  color: brown;
  font-weight: 700;
  font-family: cursive;
}

/* #ipsLayout_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
} */
/* for select */
.dropdown__box {
  margin-top: 14px !important;
  position: relative;
  color: #333;
}

.dropdown__box label {
  position: absolute;
  top: -17px;
  left: 3px;
  font-size: 16px;
  padding: 0px 4px;
  /* color: #212529; */
  /* color: #000000a8; */
  /* transition: 0.2s;/ */
  /* transition-property: opacity, transform; */
  font-weight: 700;
  /* font-style: normal; */
  /* font-stretch: normal;/ */
  /* font-optical-sizing: auto; */
  /* text-align: start; */
  /* opacity: 1; */
  /* font-family: "Raleway variant0", Tofu; */
  font-family: 'Poppins', sans-serif;
  /* letter-spacing: 2px; */
  /* color: #6b6b6b; */
  /* color: #474040; */
  color: #2e323c;
  font-size: .75rem;
  text-transform: uppercase;
}

.dropdown__box_req {
  margin-top: 14px !important;
  position: relative;
  color: #333;

}

.dropdown__box_req label {
  position: absolute;
  top: -18px;
  left: 3px;
  font-size: 12px;
  padding: 0px 4px;
  color: #333;
  font-weight: bold;
}

/* for datetime-picker */
.date__box {
  margin-top: 14px;
  position: relative;
  color: #333;

}

.date__box label {
  position: absolute;
  top: -14px;
  left: 6px;
  font-size: 12px;
  padding: 0px 4px;
  color: black;
  font-weight: bold;
}

.date__box_req {
  margin-top: 14px;
  position: relative;
  color: #333;

}

.date__box_req label {
  position: absolute;
  top: -18px;
  left: 6px;
  font-size: 12px;
  padding: 0px 4px;
  color: #333;
  font-weight: bold;
}

.react-datepicker {
  z-index: 1099 !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  overflow: visible;
  width: 100%;
  /* border: 1px solid #9E9E9E; */
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0px 6px;
  /* height: 30px; */
  height: 31px;
  margin-top: 4px;
}

.react-datepicker-wrapper .react-datepicker__input-container input:focus {
  /* border: 1px solid #ddd; */
  outline: none;
  border: 1px solid #4690e1 !important;
  box-shadow: none;

}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e6e6f2;
}

/* old */
/* .table thead th,
.table th {
  color: #d93065;
  font-family: 'Circular Std Medium';
  font-weight: 700;
} */
.table thead th,
.table th {
  color: #000000DE;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 12px;
}

.table-bordered {
  border: 1px solid #e6e6f2;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #e6e6f2;
}

body *::-webkit-scrollbar-thumb {
  background: #bec2c6 !important;
}

.css-1rzuuwd-control {
  /* border-color: #9E9E9E !important; */
  border-color: #ced4da !important;
}

.payment-button {
  width: 10px;
  text-align: center;
}

.payment-serial {
  width: 10px;
  text-align: center;
  line-height: 36px;
}

.payment-amount {
  width: 243px !important;
}

.payment-amount1 {
  width: 170px !important;

}

/* @media (max-width: 1075px) {
  .payment-amount {
    width: 180px !important;
  }
} */

.bb {
  border-bottom: 1px solid #dee2e6;
}

/* .headerdrop__arrow:after {
  content: "";
  position: absolute;
  top: 3px;
  right: -15px;
  border: solid #153a5b;
  border-width: 0 0 2px 2px;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
} */
legend {
  padding: 0;
  font-size: 15px !important;
  margin-bottom: 0px !important;
  float: none !important;
  width: auto !important;
  font-weight: bold;
  color: blue !important;

}

fieldSet {
  border-radius: 9px !important;
  padding: 15px !important;
}

/* fieldSet {
  border: 1px solid #E6E9ED;
  border: 1px solid #b8c7db !important;
  border-radius: 3px !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  margin-top: -7px !important;
  
}

legend {
  padding: 0px 7px !important;
  font-size: 14px !important;
  margin-bottom: -12px !important;
  color: blue !important;
  font-weight: 400 !important;
  width: 0px !important;
  float: none !important;
} */

.bg-green {
  /* background-color: #d93065 !important; */
  background-color: #1265a1 !important;
}

.label-field {
  color: #6b6b6b;
  font-size: 13px;
  text-transform: uppercase;
  /* letter-spacing: 1px; */
  font-weight: 600;
}


.form-required {
  color: #e6674a;
}

.form-control {
  /* border: 1px solid darkgray !important; */
  border: 1px solid #ced4da !important;

}

/* for tablet view */
@media (min-width: 400px) and (max-width:1075px) {
  .label-field {
    font-size: 11px;
  }

}


@media (max-width:990px) {
  .label-key {
    margin-left: -62px;
  }

}

/*todo for table header bold */
/* for live */
.bMpwkv {
  font-size: 13px !important;
  font-weight: 700 !important;
}

/* for local */
.jlPZUL {
  font-size: 13px !important;
  font-weight: 700 !important;
}

/* for live */
.jNovqQ {
  background-color: blanchedalmond !important;
}

/* for loacal */
.jlMdMk {
  background-color: blanchedalmond !important;
}


.disabled {
  pointer-events: none;
  opacity: 0.6;
}

/* for dashboard title */
.dashboard-title {
  /* font-family: 'Poppins', sans-serif !important; */
  font-family: system-ui;
  color: brown;
  text-shadow: 1px 2px 4px brown;
}



.chart-box {
  /* background-color: #f3f3f3 !important; */
  background-color: #f0ffff63 !important;
  border: 1px solid #cdd4e0 !important;
  padding: 10px 10px;
}

/* .recharts-surface{
  width: 480px !important;
}
.recharts-default-legend{
  text-align: inherit !important;
} */

.box-check {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 4px;
}

/* todo for new spinner in the website */
.loader11 {
  width: 100px;
  height: 70px;
  margin: 50px auto;
  position: relative
}

.loader11 span {
  display: block;
  width: 5px;
  height: 10px;
  background: #e43632;
  position: absolute;
  bottom: 0;
  animation: loading-11 2.25s infinite ease-in-out
}

.loader11 span:nth-child(2) {
  left: 11px;
  animation-delay: .2s
}

.loader11 span:nth-child(3) {
  left: 22px;
  animation-delay: .4s
}

.loader11 span:nth-child(4) {
  left: 33px;
  animation-delay: .6s
}

.loader11 span:nth-child(5) {
  left: 44px;
  animation-delay: .8s
}

.loader11 span:nth-child(6) {
  left: 55px;
  animation-delay: 1s
}

.loader11 span:nth-child(7) {
  left: 66px;
  animation-delay: 1.2s
}

.loader11 span:nth-child(8) {
  left: 77px;
  animation-delay: 1.4s
}

.loader11 span:nth-child(9) {
  left: 88px;
  animation-delay: 1.6s
}

@keyframes loading-11 {
  0% {
    height: 10px;
    transform: translateY(0);
    background: #ff4d80
  }

  25% {
    height: 60px;
    transform: translateY(15px);
    background: #3423a6
  }

  50% {
    height: 10px;
    transform: translateY(-10px);
    background: #e29013
  }

  100% {
    height: 10px;
    transform: translateY(0);
    background: #e50926
  }
}


/* CHART DATE AND CHART */

.date-picker-popup .dateType {
  font-size: 14px;
  cursor: pointer;
  margin: 5px 0;
  border: 1px solid #08a7ef;
  border-radius: 12px;
  padding: 2px 10px;

}

.dateType:hover {
  color: #ffffff;
  background-color: #0082c8;
}

.showDate-picker {
  position: absolute;
}

.ChartDateRange {
  border: 1px solid #08a7ef !important;
  border-radius: 12px !important;
  font-size: 14px;
  height: 27px !important;
}


.navbar-light .navbar-nav .nav-link {
  color: blue;
  font-size: 12px;
}

.nav-item a {
  text-decoration: none !important;
}

.card-title {
  border-bottom: 2px solid gray !important;
  border-top: 2px solid gray !important;
}

.input-p {
  color: #000;
  font-size: 13px;
  font-weight: 500;
}

/* .input-box{
  background-color: blanchedalmond !important;
  padding: 1px 10px;
  border-radius: 5px;
} */

#input {
  opacity: 0;
}

#input:focus {
  opacity: 1 !important;
}

.back-box {
  background-color: transparent;
  /* Set the background color to transparent */
  padding: 10px;
  /* Adjust padding as needed */
}

.back-box:focus-within {
  background-color: #f0f0f0;
  /* Change background color when focused */
}

/* Optional: Style for the input fields */
.back-box input[type="text"] {
  border: 1px solid #ccc;
  /* Example border style */
  padding: 8px;
}

.input-p {
  display: none;
  /* Hide .input-p by default */
  margin-top: 10px;
  /* Adjust margin as needed */
  cursor: pointer;
  /* Change cursor to pointer to indicate it's clickable */
}

.back-box:focus-within .input-p {
  display: block;
  /* Show .input-p on hover or focus within .back-box */
}

.report-list a {
  text-decoration: none !important;
}

.card {
  box-shadow: 2px 4px 5px gray;
}

.chart-container {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  height: 100%;
  align-content: center;
}

.ghLWkf div:first-child {
  width: 100%;
}

.glYZmF div:first-child {
  width: 100%;
}

.footer {
  font-size: .875rem;
  padding: 1.25rem 0;
  color: #9aa0ac;
  position: fixed;
  width: 98%;
  right: 0;
  left: 0;
  bottom: 0px;
  background: white;
  border-top: 1px solid;
}

.sc-ePzlA-D {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow: auto;
  min-height: 35vh !important;
  max-height: 35vh !important;

  /* min-height: 67vh !important; */
  /* max-height: 67vh !important; */
}

@media (max-width: 1320px) {
  .sc-ePzlA-D {
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow: auto;
    min-height: 62vh !important;
    max-height: 62vh !important;
  }
}


/*-------------------------- sideBar---------------------------- */





/* abhi */



.navbar .navbar-nav .nav-item a {
  font-size: 14px !important;
  font-weight: 500;
  /* color: #189AB4 !important; */
}
.home-text{
   color: #189AB4 !important;
}

.main_content{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.bg-light_shadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}



/* abhi */