.form-bg {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container {
    background: linear-gradient(150deg, #1B394D 33%, #2D9DA7 34%, #2D9DA7 66%, #EC5F20 67%);
    font-family: 'Raleway', sans-serif;
    text-align: center;
    padding: 30px 20px 50px;
}

.form-container .title {
    color: #fff;
    font-size: 23px;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin: 0 0 60px;
}

.form-container .form-horizontal {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

.form-horizontal .form-icon {
    color: #fff;
    background-color: #1B394D;
    font-size: 75px;
    line-height: 92px;
    height: 90px;
    width: 90px;
    margin: -65px auto 10px;
    border-radius: 50%;
}

.form-horizontal .form-group {
    margin: 0 0 10px;
    position: relative;
}

.form-horizontal .form-group:nth-child(3) {
    margin-bottom: 30px;
}

.form-horizontal .form-group .input-icon {
    color: #e7e7e7;
    font-size: 23px;
    position: absolute;
    left: 0;
    top: 10px;
}

.form-horizontal .form-control {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
    padding: 10px 10px 10px 40px;
    margin: 0 0 5px;
    border: none;
    border-bottom: 2px solid #e7e7e7;
    border-radius: 0px;
    box-shadow: none;
}

.form-horizontal .form-control:focus {
    box-shadow: none;
    border-bottom-color: #EC5F20;
}

.form-horizontal .form-control::placeholder {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.form-horizontal .forgot {
    font-size: 13px;
    font-weight: 600;
    text-align: right;
    display: block;
}

.form-horizontal .forgot a {
    color: #777;
    transition: all 0.3s ease 0s;
}

.form-horizontal .forgot a:hover {
    color: #777;
    text-decoration: underline;
}

.form-horizontal .signin {
    color: #fff;
    background-color: #EC5F20;
    font-size: 17px;
    text-transform: capitalize;
    letter-spacing: 2px;
    width: 100%;
    padding: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease 0s;
}

.form-horizontal .signin:hover,
.form-horizontal .signin:focus {
    font-weight: 600;
    letter-spacing: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
}


/* new login page */
/* Importing fonts from Google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Reseting */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    background: #ecf0f3;
}

.wrapper {
    max-width: 350px;
    /* min-height: 500px; */
    margin: auto;
    /* padding: 40px 30px 30px 30px; */
    background-color: #ecf0f3;
    border-radius: 15px;
    /* box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff; */
    box-shadow: 13px 13px 20px #3d5e7dc9, -13px -13px 20px #fff;
    transform: translate(10px, 56px);
}

.logo {
    /* width: 80px; */
    /* margin: auto; */
    width: 100%;
    height: 100%;
    position: relative;
}

.logo img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    /* border-radius: 50%; */
    /* box-shadow: 0px 0px 3px #5f5f5f,
        0px 0px 0px 5px #ecf0f3,
        8px 8px 15px #a7aaa7,
        -8px -8px 15px #fff; */
}

.wrapper .name {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: brown;
}

.wrapper .form-field input {
    width: 100%;
    display: block;
    outline: none;
    background: none;
    /* font-size: 1.2rem; */
    color: #666;
    padding: 10px 15px 10px 10px;
    border: 1px solid #cbced1;
    border-radius: inherit;

}

.wrapper .form-field input:focus {
    border-bottom: 1px solid #cbced1;
    border-bottom-color: lightsteelblue !important;
}


.wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
    justify-content: right;

}

.wrapper .form-field .fas {
    color: #555;
}

/* .wrapper .btn {
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #03A9F4;
    color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff;
    letter-spacing: 1.3px;
} */

/* .wrapper .btn:hover {
    border: none;
    outline: none;
} */

.wrapper a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03A9F4;
}

.wrapper a:hover {
    color: #d93065;
}

@media(max-width: 380px) {
    .wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px;
    }
}

.sign-up {
    margin-top: -20px !important;
}

.btn-hover:hover {
    color: #fff !important;
}

/* for new text in img */
.Mines-text {
    position: absolute;
    left: 67px;
    /* right: 16px; */
    top: 86px;
    text-align: center;
    color: aliceblue;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 2px;
    animation: typing 5s infinite;
    box-sizing: border-box;
    text-align: center;
    font-family: 'FontAwesome';
    user-select: none;
}

@keyframes typing {
    from {
        transform: scale(1.2);
    }

    to {
        transform: scale(0.5);

    }
}


/* todo for all img wrapper */
.wrapper:hover {
    /* background: linear-gradient(to right, #45a247, #283c86); */
    /* background: linear-gradient(to right, #d3d3d3, #e3e3e3); */
    /* background: linear-gradient(to right, #d3d3d3, #e3e3e3); */
    background: linear-gradient(to right, #fffdfd, #bfbfbf);

}