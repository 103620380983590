body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
  background-color: #fff !important;
  overflow-x: hidden !important;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tab-menu.nav--box {
  /* width: 795px !important; */
  left: 17.8% !important;
  border-radius: 0 0 12px 12px !important;
  /* border: 1px solid rgba(21, 58, 91, .1) !important; */
  border-top: 0 !important;
  box-shadow: none !important;
  width: max-content;
}

.sublinks {
  display: flex;
  align-items: baseline;
  margin-top: 0
}

.sublinks ul {
  margin-right: 15px;
  border-right: 1px solid rgba(216, 216, 216, .4) !important;
  flex: 1;
  flex-flow: column;
  align-items: baseline;
  text-decoration: none;
  list-style: none;
}

.tab-menu .nav-item>a {
  border: 0;
  font-size: 16px;
  color: #153a5b;
  text-transform: uppercase;
  padding: 0;
}

.tab-menu .nav-item {
  flex: 1;
  margin-bottom: 0;
  margin-right: 15px;
  color: #153a5b;
  font-weight: 600;
  position: relative;
}

.tab-menu .nav-tabs {
  display: block;
}

.nav-item .sublinks ul {
  margin-right: 15px;
  border-right: 1px solid rgba(216, 216, 216, .4) !important;
  padding: 0 16px;
  margin: 0;
}

.nav-item .sublinks ul li a {
  line-height: 1.2;
  color: #153a5b;
  text-decoration: none !important;
  font-weight: 400;
  padding: 5px 0;
  display: block;
  font-size: 14px;
}

.nav-item .sublinks ul li:hover {
  /* background-color: rgba(var(--theme-text_color), 0.05) !important; */
  background-color: #e9f5ff !important;
  color: rgb(var(--theme-text_color)) !important;
  width: 100%;
}

.sublinks ul {
  flex: 1;
  flex-flow: column;
  align-items: baseline;
}

.mf__heading {
  padding: 7px 0 !important;
  background: #e9f5ff !important;
  text-align: center;
  color: #153a5b;
  font-size: 14px !important;
  width: 100%;
  border: 0;
  display: block;
  /* margin-top: -8px; */
  text-decoration: none;
  font-weight: 800;
  text-transform: uppercase;
}

.sublinks ul li {
  padding-right: 0;
  margin-bottom: 3px;
  /* width: max-content; */
}

@media (min-width: 481px) and (max-width: 768px) {
  .tab-menu.nav--box {
    width: auto;
  }

}


@media (min-width: 481px) and (max-width: 768px) {
  .dropdown-menu.show.dropsubmenu {
    margin-left: -156px;
    width: max-content;
  }
}

@media (max-width: 1400px) {
  .dropdown-menu.show.dropsubmenu {
    display: block;
    /* transform: translate3d(-80%, 49px, 0px) !important; */
    position: absolute !important;
    top: 0px !important;
    left: 0 !important;
    will-change: transform !important;
    overflow-y: scroll;
    max-height: 100vh;
  }
}

@media (min-width: 1255px) {
  .dropdown-menu.show.dropsubmenu {
    display: block;
    transform: translate3d(-80%, 49px, 0px) !important;
    position: absolute !important;
    top: 0px !important;
    left: 0 !important;
    will-change: transform !important;
    overflow-y: scroll;
    max-height: 100vh;
  }
}

.dropdown-menu.show {
  display: block;
  transform: translate3d(0px, 20px, 0px) !important;
  position: absolute !important;
  top: 0px !important;
  left: 0 !important;
  will-change: transform !important;
  /* overflow-y: scroll;
  max-height: 100vh; */
}

.dropdown-menu.show.dropsubmenu {
  display: block;
  /* transform: translate3d(-117px, 26px, 0px) !important; */
  position: absolute !important;
  top: 0px !important;
  left: 0 !important;
  will-change: transform !important;
  overflow-y: scroll;
  max-height: 100vh;
}

.dropdown-menu.show.nav-user-dropdown {
  display: block;
  transform: translate3d(-80px, 15px, 0px) !important;
  position: absolute !important;
  top: 16px !important;
  left: 20px !important;
  will-change: transform !important;
}

.dropdown-menu.show.nav-filter-dropdown {
  display: block;
  /* transform: translate3d(0px, 35px, 0px) !important; */
  transform: translate3d(-97px, 35px, 0px) !important;
  position: absolute !important;
  top: 0px !important;
  left: 0 !important;
  will-change: transform !important;
}

.dropdown-menu {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.25) !important;
}

body *::-webkit-scrollbar {
  width: 10px !important;
}

/* .bg-green{
  background: linear-gradient(90deg, #d93065 -6.03%, #95113b 109.91%) !important;
  box-shadow: 0 4px 13px rgb(45 0 14 / 31%) !important;
  border-color: #d93065 !important;
} */
/* .header-active a:active{
  color: #c0083e !important ;
} */
.ipsNavBar_secondary>li>a:active {
  color: #c0083e !important;
}

.dropdown-menu a {
  text-decoration: none !important;
}

.nav-tabs .nav-link.active {
  border-color: #d93065 !important;
  /* color: #fff !important; */
}


/* / Loader Class / */
.parent-loader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.parent-loader .child-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 50px;
}

#dropdown-basic {
  font-size: 15px !important;
  border: none !important;
  /* background-color: #d93065; */
  background-color: #1265a1;
  /* box-shadow: none !important; */
}

/* for only time date box in challan */
.react-datepicker__time-container {
  /* float: none !important; */
  border-left: 1px solid #aeaeae;
  width: 100px !important;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 0px 0 0px 0px !important;
  text-align: left;
}

/* for select in challan */
@media (max-width: 995px) {
  .challan-consignee {
    margin-top: 21px !important;
  }
}

@media (min-width: 995px) {
  .challan-consignee {
    margin-top: 14px !important;
  }
}

.challan-consignee {
  /* margin-top: 14px !important; */
  position: relative;
  color: #333;
}

.challan-consignee label {
  position: absolute;
  top: -20px;
  left: 3px;
  font-size: 15px;
  padding: 0px 4px;
  color: black;
  transition: 0.2s;
  transition-property: opacity, transform;
  /* font-weight: 300; */
  font-style: normal;
  font-stretch: normal;
  font-optical-sizing: auto;
  text-align: start;
  opacity: 1;
  /* font-family: "Raleway variant0", Tofu; */
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
}

/*todo for body scroll */
.body-mines {
  padding: 0px !important;
  margin: 0px !important;
  /* width: 99% !important; */
  width: 100% !important;
  height: 100vh !important;
  overflow-y: scroll !important;
}

/* todo for btn-list-table-edit */
.master-btn:focus {
  /* box-shadow: none !important; */
  outline: none !important;
  /* background-color: #d93065  !important;
  border-color: #d93065 !important; */
  background-color: #1265a1 !important;
  border-color: #1265a1 !important;
}

/* .btn:focus{
  box-shadow: 0 4px 13px rgb(45 0 14 / 31%) !important;
  outline: none !important;
} */

/* for clickabbe field */
/* .arrow-header .title {
  font-size: 17px;
  color: #000;
  font-weight: 500;
} */

.arrow-header .accicon {
  float: right;
  font-size: 25px;
  /* width: 30px; */
  /* background-color: #1265a1; */
  border-radius: 50%;
  text-align: center;
  line-height: 0px;
  /* color: #fff; */
}

.arrow-header {
  cursor: pointer;
  border-bottom: none;
  /* background-color: rgba(0,0,0,.07); */
  background-color: #223035;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  color: #fff;
}

/* .arrow {
  border: 1px solid #ddd;
} */

.arrow-body {
  border-top: 1px solid #ddd;
}

.arrow-header:not(.collapsed) .rotate-icon {
  transform: rotate(180deg);
}


.party-sale {
  position: relative;
  margin-top: 12px;
}

.party-sale input,
.party-sale textarea {
  display: inline-block;
  border: 1px solid #9E9E9E;
  /*  border-bottom: 1px solid var(--green);  */
  color: var(--labelColor);
  background-color: #fff;
  padding: 5px 8px 3px 8px;
  width: 100%;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 4px;
}


.party-sale input:focus,
.party-sale textarea:focus {
  /* border: 1px solid #dddddd !important; */
  border: 1px solid #4690e1 !important;
  background-color: #fff;
  outline: none;
}

.party-sale label {
  /* color: #6b6b6b; */
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  left: 3px;
  top: -19px;
  transition: 0.2s;
  transition-property: opacity, transform;
  /* font-weight: 300; */
  font-style: normal;
  font-stretch: normal;
  font-optical-sizing: auto;
  text-align: start;
  opacity: 1;
  /* font-family: "Raleway variant0", Tofu; */
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
}

/* for select in challan */
@media (max-width: 995px) {
  .party-sale {
    margin-top: 25px !important;
  }
}

@media (min-width: 995px) {
  .party-sale {
    margin-top: 12px !important;
  }
}



/*todo for left modal */
.modal.left .modal-dialog {
  position: fixed;
  right: 0;
  margin: auto;
  /* width: 320px; */
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content {
  height: 100%;
  overflow-y: auto;
  width: 500px !important;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}


.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 3px;
  left: 100%;
  margin-top: -6px;
  /* margin-left: -1px; */
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
  border: none;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
  color: #c0083e;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #c0083e;
}

.dropdown-submenu.pull-left {
  float: none;
}

/* .dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
} */


/* for familydetails select */
.dropdown__table {
  margin-top: 12px;
}

@media (max-width: 870px) {
  .dropdown__table {
    width: 220px;
  }
}

.sub-field {
  color: #353c41 !important;
}

/* for new list */
.mb-0>a {
  display: block;
  position: relative;
  text-decoration: none;
}

.mb-0>a:after {
  content: "\f077";
  /* fa-chevron-down */
  font-family: 'FontAwesome';
  float: right;
}

.mb-0>a[aria-expanded="true"]:after {
  content: "\f078";
  /* fa-chevron-up */
  float: right;
}

.card-header {
  display: inline-block;
  background-color: cornflowerblue !important;
  color: #000 !important;
  padding: 5px !important;
}



/*todo for img-box make animation */
/* @keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

.front-img-box {
  background-color: #fff;
  color: var(--black);
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%), 0 0px 5px rgb(0 0 0 / 23%);
  border-radius: 4px;
  padding: 14px 4px 10px 4px;
}
:root {
  --mainColor: #bd0001;
  --white: #fff;
  --black: #222;
}

.img-box-hover {
  --border-size: 2px;
  --border-angle: 0turn;
  background-image: conic-gradient(from var(--border-angle), #fff, #fff 50%, #fff), conic-gradient(from var(--border-angle), transparent 20%, #08f, var(--mainColor));
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: bg-spin 3s linear infinite;
  animation: bg-spin 3s linear infinite;
  transition: 0.5s ease-in-out;
}

@-webkit-keyframes bg-spin {
  to {
      --border-angle: 1turn;
  }
}

@keyframes bg-spin {
  to {
      --border-angle: 1turn;
  }
}

.img-box-hover:hover {
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
} */




/*todo for big list dropdown */
.dropdown-large {
  position: static !important;
}


.big-drop {
  list-style: none;
}



.big-drop li a {
  display: block !important;
  padding: 5px 0px !important;
  color: rgb(var(--theme-text_color)) !important;
  width: auto;
  margin: 0 !important;
}

.big-drop li a:hover {
  background-color: #e9f5ff !important;
  color: #95113b !important;
}

.big-line {
  color: brown;
  font-weight: 600;
}

/* for react datepicker */
.react-datepicker-popper {
  width: max-content !important;
  z-index: 999 !important;
  /* position: absolute !important;
  transform: translate(0px, 42px) !important;
  padding-top: 0px !important; */
}

.date-box {
  margin-top: -8px;
  width: max-content;
}

.drop-date {
  position: relative !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.date-box input:focus {
  /* border: 1px solid #ddd; */
  outline: none;
  border: 1px solid #4690e1 !important;
  box-shadow: none;
}

.date-box textarea:focus {
  /* border: 1px solid #ddd; */
  outline: none;
  border: 1px solid #4690e1 !important;
  box-shadow: none;
}


/* for login page text */
/* .typewriter-text {
  letter-spacing: 2px;
  animation: typing 5s infinite;
  box-sizing: border-box;
  text-align: center;
}

@keyframes typing {
  from { 
    transform: scale(1.2);
  }
 
  to { 
    transform: scale(0.3);
    
  }
} */
/* .table-img i{
  background-color: gray;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  padding-top: 7px;
} */
.user-task {
  color: black !important;
  font-weight: 600 !important;
}

.name-task {
  color: #000000bf !important;
  font-weight: 500;
  text-align: justify;
  user-select: none;
}

.task-date {
  width: 200px;
  color: gray;
  font-weight: 600;
}

/*todo for attendance table */
tbody,
td,
tfoot,
tr {
  border-color: inherit;
  border-style: dashed !important;
  border-width: 0;
}

thead {
  border: 2px solid #ddd !important;
  border-left: none !important;
  border-right: none !important;
  background-color: blanchedalmond;
}

.custom-width {
  width: 100px !important;
}

.table td,
.table th {
  /* border-color: #799999 !important; */
  border-color: lightgray !important;
  font-size: 14px;
}


/* todo for new login */
/* .background-radial-gradient {
 background-color: hsl(218, 41%, 15%);
background-image: radial-gradient(650px circle at 0% 0%,
hsl(218, 41%, 35%) 15%,
hsl(218, 41%, 30%) 35%,
hsl(218, 41%, 20%) 75%,
hsl(218, 41%, 19%) 80%,
transparent 100%),
radial-gradient(1250px circle at 100% 100%,
hsl(218, 41%, 45%) 15%,
hsl(218, 41%, 30%) 35%,
hsl(218, 41%, 20%) 75%,
hsl(218, 41%, 19%) 80%,
transparent 100%); 
min-height: 100vh;
 background-image: url('/src/Component/Pages/Login/img_new.jpg');
background-size: cover;
height: 100vh;
background-position: center;
background-color: hsla(0, 0%, 100%, 0.9) !important;
backdrop-filter: saturate(200%) blur(25px); 
} */
.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%),
    radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%);
  min-height: 100vh;
  /* background-image: url('/src/Component/Pages/Login/img_new.jpg');
 background-size: cover;
filter: saturate(1.5);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative; */
}


#radius-shape-1 {
  height: 220px;
  width: 220px;
  /* top: -60px;
left: -130px; */
  top: -43x;
  left: -108px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -50px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  /* background-color: hsl(0deg 0% 100% / 88%) !important; */
  backdrop-filter: saturate(200%) blur(25px);
}

.bg-glass:hover {
  background-color: rgb(224, 220, 220) !important;
  /* background-color: hsl(0deg 0% 100% / 88%) !important; */
  backdrop-filter: saturate(200%) blur(25px);
  box-shadow: 1px 1px 10px 1px gray !important;
}

.bg-glass .name {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1.3px;
  padding-left: 10px;
  color: brown;
}

.form-control.form-login {
  border: 1px dashed gray !important;
}

.form-control.form-login:focus {
  border: 1px solid #2684FF !important;
}

/* .newlogin-span{
  color: #1265a1 !important;
  color: #1265a1c7 !important;
  font-size: 40px !important;
} */

/* todo for custom table select cancel */
.select-cancel {
  position: absolute;
  top: 2px;
  right: 12px;
  color: #808080a1;
  font-size: 20px;
}

/* todo for custom table select search */
/* .select-search{
  position: absolute;
  top: 2px;
  color: #808080a1;
  font-size: 20px;
} */
/* todo for attendance name-link */
.attendance-name {
  text-decoration: none;
  color: #8b0000d1;
  font-weight: 600;
}

/* todo for new filedset in meter-reading */
/* .new-border {
  border-radius: 2px !important;
} */

/* .master-list{
  color: #1265a1 !important;
  text-decoration: underline !important;
  padding: 3px !important;
  font-weight: 500;
} */
/* new */
.master-list {
  color: #333 !important;
  /* text-decoration: underline !important; */
  padding: 5px 10px !important;
  font-weight: 500;
}

.master-list:hover {
  /* color: #000 !important; */
  color: #1773ea !important;
  text-decoration: underline !important;
}

.new-scroll *::-webkit-scrollbar {
  width: 6px !important;
}




.search-field {
  background-color: transparent;
  background-image: url(/src/Component/img/search-icon.png);
  background-position: 5px center;
  background-repeat: no-repeat;
  /* background-size: 24px 24px; */
  border: none;
  cursor: pointer;
  height: 22px;
  margin: 3px 97px;
  padding: 0 0 0 34px;
  position: relative;
  transition: width 400ms ease, background 400ms ease;
  width: 0px;
  cursor: pointer;
}

.search-field:focus {
  /* background-color: #000;
	border: 2px solid #c3c0ab;
	cursor: text;
	outline: 0;
	width: 250px;
  color:#fff; */
  background-color: #9e9e9ee0;
  border: 2px solid #c3c0ab;
  cursor: text;
  outline: 0;
  width: 190px;
  color: #000;
  margin-left: -50px;
  padding: 16px 30px;


}



.growth-bg-img {
  height: 125px;
  width: 100%;
  background-position: bottom;
  border-radius: 6px;
  padding: 1rem;
  flex: 1;
}

.bg-img-profit {
  background-image: url(/src/Component/img/download.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img-expenses {
  background-image: url(/src/Component/img/download\ \(1\).svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img-income {
  background-image: url(/src/Component/img/download\ \(2\).svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img-income .img-icon .sm-card {
  background-color: #00cfe8;
}

.bg-img-expenses .img-icon .sm-card {
  background-color: #ea5455;
}

.bg-img-profit .img-icon .sm-card {
  background-color: #28c76f;
}

.growth-bg-img .img-icon .sm-card,
.revenue-pro-card .img-icon .sm-card {
  height: 50px;
  width: 50px;
  border-radius: 6px;
}


.rev-inflow-card .img-icon {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 15px;
  align-items: center;
}

.rev-inflow-cash-collect .img-icon .sm-card {
  background: rgba(0, 207, 232, .5);
}

.rev-inflow-bank .img-icon .sm-card {
  background: rgba(234, 84, 85, .5);
}

.rev-inflow-pay .img-icon .sm-card {
  background: rgba(115, 103, 239, .5);
}

.rev-inflow-cash-bal .img-icon .sm-card {
  background: rgba(40, 199, 111, .5);
}

.rev-inflow-bank-bal .img-icon .sm-card {
  background: #1773ea;
  opacity: .5;
}

.rev-inflow-tax-pay .img-icon .sm-card {
  background: rgba(255, 159, 67, .5);
}

.rev-inflow-card .img-icon .sm-card {
  width: 80px;
  height: 80px;
  border-radius: 50px;
}

/* todo for select fields local */
.css-1hb7zxy-IndicatorsContainer {
  margin-top: -2px !important;
}

/* todo for select fields live */
.css-1wy0on6 {
  margin-top: -2px !important;
}


/* todo for box in dashborad icon */


/* .main-dashboard .box-container .box .fa {
  font-size: 30px;
  padding-top: 15px;
} */
.main-dashboard .box-container .box {
  /* height: 230px; */
  /* width: 250px; */
  background-color: #fff;
  box-shadow: 1px 2px 6px gray;
  border-radius: 5px;
  margin: 5px 7px;
  /* padding-bottom: 10px;  */
  text-align: center;
  font-size: small;
  text-decoration: none !important;
}

.main-dashboard .box-container .box p {
  color: #274c77;
  font-size: 15px;
  padding: 5px;
  text-shadow: 0 0 black;
  background-color: whitesmoke;
  text-decoration: none !important;
}

.main-dashboard .box-container .box p:hover {
  color: #28c76f;
}

.hr-images {
  height: 200px;
  width: auto;
  background-color: #fff;
  border-radius: 5px;
}

.dash-p {
  text-decoration: none !important;
}

.nav-link.active {
  font-size: 12px;
  font-weight: 500;
}

/* .new-label {
  font-size: 13px;
  color: #283041;
  font-weight: 500;
  float: right;
} */


.nav-item {
  text-align: center !important;
  padding: 5px 1px 2px 0px !important;
  /* margin-top: 5px; */
}

.nav-item.active {
  background-color: #f0f0f0;
  /* Set your desired background color */
}


.nav-link {
  /* color: #fff !important; */
  text-align: center !important;
  padding: 6px 10px !important;
}

.nav-user {
  position: absolute;
  top: -10px;
}

.new-label {
  font-size: 12px;
  color: #283041;
  font-weight: 500;
  float: right;
  user-select: none;
}

/* 
@media (max-width: 779px) {
  .ipsResponsive_showDesktop {
    display: none;
  }

}

@media (min-width: 780px) {
  .ipsResponsive_showDesktop1 {
    display: none;
  }


}

.navbar-nav {
  display: flex !important;
  flex-direction: row !important;
} */


/* abhi */
.container-fluid{
  padding: 0px !important;
}


/* abhi */